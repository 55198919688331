import { parallax } from '@immutabl3/alchemy';
import { useStore } from '@immutabl3/store/react';
import telescope from './telescope.animation.json';
import TelescopeNumbers from './TelescopeNumbers';
import style, {
  line as lineStyle,
  logo as logoStyle,
  logoReflection as logoReflectionStyle,
} from './Telescope.style';

const {
  line,
  logo,
  content,
  subContent,
  logoReflection,
} = telescope;

export default function Telescope() {
  const {
    companyName,
    companyLogoUrl,
  } = useStore(['user', 'personalization']);

  return (
    <>
      <parallax.div css={ style } beaker="chapter1" animation={ content }>
        <p className="strong text-display">
          Greatness means consistently<br />
          pushing beyond the other 99%
        </p>
        <parallax.p beaker="chapter1" animation={ subContent } className="text-display">
          More time, energy, and resources are an obvious requirement
        </parallax.p>
        <parallax.div
          css={ logoStyle }
          data-name={ companyName }
          beaker="chapter1"
          animation={ logo }
        >
          { !!companyLogoUrl && (
            <div style={ { backgroundImage: `url(${companyLogoUrl})` } } />
          ) }
        </parallax.div>
        <parallax.div
          css={ logoReflectionStyle }
          beaker="chapter1"
          animation={ logoReflection }
        />
        <TelescopeNumbers />
      </parallax.div>
      <div css={ lineStyle }>
        <parallax.div beaker="chapter1" animation={ line } />
      </div>
    </>
  );
};