import { Frame } from '@immutabl3/alchemy';
import Opener from './Opener';
import ValueProposition from './ValueProposition';
import QuestionAnswer from './QuestionAnswer';
import Closing from './Closing';

export default function Chapter1Opener() {
  return (
    <>
      <Frame flex="column" beaker="chapter2">
        <Opener />
      </Frame>
      <ValueProposition />
      <Frame flex="column" beaker="chapter2">
        <QuestionAnswer />
      </Frame>
      <Frame flex="column" beaker="chapter2">
        <Closing />
      </Frame>
    </>
  );
};