import onBlurUpdate from '../utils/onBlurUpdate';
import animations from './closing.animation.json';

export default Object.fromEntries(
  Object.entries(animations)
    .map(([key, anim]) => ([
      key, key === 'maskTop' || key === 'maskBottom' 
        ? anim.map(anim => ({ ...anim, update: onBlurUpdate }))
        : anim
    ]))
);