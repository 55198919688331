import style from './PreloadTransitionMask.style';
import { parallax } from '@immutabl3/alchemy';
import animation from './preloadTransitionMask.animation.json';

export default function PreloadTransitionMask() {
  return (
    <parallax.div
      css={ style }
      animation={ animation }
      className="no-interact"
    />
  );
};