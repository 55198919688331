import { useCallback, useRef } from 'react';
import gateway from './gateway.animation';
import Disclaimer from './Disclaimer';
import { useStore } from '@immutabl3/store/react';
import style from './Gateway.style';
import circleButtonStyle from '../../CircleButton.style';
import {
  useSignal,
} from '@artica/hooks';
import {
  useGate,
  parallax,
  useIsBetween,
  useConvertTimeToScroll,
} from '@immutabl3/alchemy';

export default function Gateway() {
  const ref = useRef();
  const shouldLock = !useStore(['user', 'accepted']);
  const { isGated, unlock } = useGate('chapter1', gateway.gate, shouldLock);
  const signal = useSignal();
  const top = useConvertTimeToScroll('chapter1', gateway.unlock.timestamp);

  const onUnlock = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    signal.emit('user:accept');
    unlock({
      top,
      duration: gateway.unlock.duration * 1000,
    });
  }, [signal, unlock, top]);

  // NOTE: using isVisible instead of <Visible /> to 
  // ensure running the gateway lockout code above
  const isVisible = useIsBetween(
    'chapter1',
    gateway.animation[0].timestamp,
    gateway.animation[1].timestamp + gateway.animation[1].duration,
  );

  if (!isVisible) return null;

  return (
    <div
      ref={ ref }
      css={ style }
      data-unlocked={ !isGated }
    >
      <parallax.button
        css={ circleButtonStyle }
        onPointerDown={ onUnlock }
        beaker="chapter1"
        className="center"
        animation={ gateway.button }
      >
        show<br />
        me
      </parallax.button>
      <parallax.p beaker="chapter1" className="center small" animation={ gateway.animation }>
        <Disclaimer />
      </parallax.p>
    </div>
  );
};