import { parallax } from '@immutabl3/alchemy';
import Html from '../../Html';
import style from './Answer.style';
import sharedStyle from './QuestionAndAnswerShared.style';

import questionanswer from './questionanswer.animation';

export default function Answer({ index, answer }) {
  const animation = questionanswer.answer[index];

  return (
    <parallax.div css={ [sharedStyle, style] } beaker="chapter2" animation={ animation }>
      <Html>
        { answer }
      </Html>
    </parallax.div>
  );
};