const transformKey = new Map([
  ['blur', ['webkitBackdropFilter', 'backdropFilter']],
]);

const formatValue = new Map([
  ['blur', value => `blur(${value}px)`],
]);

export default function onBlurUpdate({ animatables, animations }) {
  for (const { target } of animatables) {
    for (const { property, currentValue } of animations) {
      const keys = transformKey.get(property) ?? [property];
      const value = formatValue.has(property)
        ? formatValue.get(property)(currentValue)
        : currentValue;
      for (const key of keys) {
        target.style[key] = value;
      }
    }
  }
};