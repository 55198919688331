import { DOMAIN_URL } from '@artica/constants';
import DefaultHead from './DefaultHead';

interface Social {
  name?: string;
  link?: string;
  handle?: string;
}

interface Input {
  meta: {
    keywords?: string;
    description?: string;
  };
  social: {
    vimeo?: Social;
    instagram?: Social;
    linkedin?: Social;
    facebook?: Social;
    twitter?: Social;
  };
};

const toUrl = (handle = '', link = ''): string => {
  if (!handle) return link;
  if (link.includes(handle)) return link;

  const joint = link.endsWith('/') ? '' : '/';
  return `${link}${joint}${handle}`;
};

export default function MetaHead({
  meta: {
    keywords,
    description,
  },
  social: {
    vimeo,
    instagram,
    linkedin,
    facebook,
    twitter,
  },
}: Input) {
  const hasVimeo = !!vimeo?.link;
  const hasInstagram = !!instagram?.link;
  const hasLinkedin = !!linkedin?.link;
  const hasFacebook = !!facebook?.link;
  const hasTwitter = !!twitter?.link && !!twitter?.handle;

  return (
    <DefaultHead>     
      { !!keywords && <meta name="keywords" content={ keywords } /> }
      { !!description && <meta name="description" content={ description } /> }

      { !!description && <meta content={ description } property="og:description" /> }

      { hasLinkedin && <meta content={ toUrl(linkedin.handle, linkedin.link) } property="og:see_also" /> }
      { hasTwitter && <meta content={ toUrl(twitter.handle, twitter.link) } property="og:see_also" /> }
      { hasFacebook && <meta content={ toUrl(facebook.handle, facebook.link) } property="og:see_also" /> }
      { hasInstagram && <meta content={ toUrl(instagram.handle, instagram.link) } property="og:see_also" /> }
      { hasVimeo && <meta content={ toUrl(vimeo.handle, vimeo.link) } property="og:see_also" /> }
      
      { hasTwitter && <meta name="twitter:card" content="summary_large_image" /> }
      { hasTwitter && <meta name="twitter:image" content={ `${DOMAIN_URL}/logo.jpg` } /> }
      { hasTwitter && <meta name="twitter:image:width" content="256" /> }
      { hasTwitter && <meta name="twitter:image:height" content="256" /> }
      { hasTwitter && <meta name="twitter:site" content={ `@${twitter.handle}` } /> }
      { hasTwitter && <meta name="twitter:creator" content={ `@${twitter.handle}` } /> }
      { hasTwitter && !!description && <meta name="twitter:description" content={ description } /> }
    </DefaultHead>
  );
};