import { useCallback } from 'react';
import { useSignal } from '@artica/hooks';
import { useStore } from '@immutabl3/store/react';

export const useSignUp = () => {
  const signal = useSignal();
  const isClosed = useStore(['user', 'emailPopupClosed']);
  const onDismiss = useCallback(() => signal.emit('user:email:dismiss'), [signal]);
  return {
    isClosed,
    onDismiss,
  };
};