import { useCallback } from 'react';
import { window } from '@immutabl3/alchemy';
import { useSignal } from '@artica/hooks';

export const useOnStart = (top, duration) => {
  return useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    window.resume();
    window.scrollTo({
      top,
      duration,
    });
  }, [top]);
};

export const useSignUp = () => {
  const signal = useSignal();

  return useCallback(e => {
    e.preventDefault();
  
    signal.emit('menu:toggle', true);

    setTimeout(() => {
      const input = document.querySelector('input[type="email"]');
      if (!input) return;
      input.focus();
    }, 500);
  }, [signal]);
};