import onBlurUpdate from '../../utils/onBlurUpdate';
import animations from './questionanswer.animation.json';

const overrideUpdate = anim => ({
  ...anim,
  update: onBlurUpdate,
});

export default {
  reticule: animations.reticule,
  question: Object.fromEntries(
    Object.entries(animations.question)
      .map(([key, timeline]) => [key, timeline.map(overrideUpdate)])
  ),
  answer: Object.fromEntries(
    Object.entries(animations.answer)
      .map(([key, timeline]) => [key, timeline.map(overrideUpdate)])
  ),
};