import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  position: absolute;
  left: 50%;
  bottom: 10%;
  translate: -50% 0;

  @media ${mq.sm} {
    bottom: 22.5%;
  }
`;