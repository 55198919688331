import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  position: relative;
  z-index: 1;
  max-width: 90%;
  margin: 30vh 1rem 0 2rem;
  padding: 0 1rem 0 0;
  aspect-ratio: 1;
  
  @media ${mq.sm} {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    max-width: 375px;
    translate: -50% 0;
    
    p {
      padding-right: 2em;
    }
  }
  
  @media ${mq.md} {
    max-width: 450px;
  }
`;

export const logo = css`
  position: absolute;
  width: 25%;
  aspect-ratio: 1;
  bottom: 0;
  right: 0;
  border: 0 hsl(
    var(--color-artica-snow-h)
    var(--color-artica-snow-s)
    var(--color-artica-snow-l)
    / 0.3
  ) solid;
  border-right-width: 2px;
  border-bottom-width: 2px;

  > div {
    width: calc(100% - 5px);
    aspect-ratio: 1;
    opacity: 0.25;
    background-color: transparent;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:after {
    content: attr(data-name);
    position: absolute;
    bottom: 0;
    left: 100%;
    opacity: 0.25;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 0.75rem;
    rotate: -90deg;
    translate: 1.25rem 0;
    transform-origin: bottom left;
  }
`;

export const logoReflection = css`
  position: absolute;
  width: 25%;
  aspect-ratio: 1;
  top: -15%;
  right: 0;
  border: 0 hsl(
    var(--color-artica-snow-h)
    var(--color-artica-snow-s)
    var(--color-artica-snow-l)
    / 0.3
  ) solid;
  border-top-width: 2px;
  border-right-width: 2px;
`;

export const line = css`
  position: absolute;
  inset: 0;
  mask: linear-gradient(
    180deg,
    transparent 0%,
    transparent 4rem,
    black 8rem
  );

  @media ${mq.md} {
    mask: linear-gradient(
      180deg,
      transparent 0%,
      transparent 7rem,
      black 14rem
    );
  }

  > div {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 2rem;
    width: 2px;
    height: 70vh;
    background-color: var(--color-artica-snow);
    transform-origin: bottom;

    @media ${mq.sm} {
      left: 15vw;
    }
    @media ${mq.md} {
      left: 20vw;
    }
    @media ${mq.lg} {
      left: 30vw;
    }
  }
`;