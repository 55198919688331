import { css } from '@emotion/react';

export default css`
  background-color: hsl(222deg 30% 10% / 0.7);
  translate: -50% calc(var(--radius) * var(--gap));

  &:after {
    bottom: 100%;
    left: 50%;
    width: 2px;
    height: calc(var(--radius) * (var(--gap) - 1));
  }
`;