import { css } from '@emotion/react';

export default css`
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  mask: linear-gradient(
    to right,
    transparent 0%,
    black 10%,
    black 90%,
    transparent 100%
  );
`;