import style from './Close.style';

export default function Close(props) {
  return (
    <button
      type="button"
      aria-label="close"
      aria-hidden={ !props.active }
      tabIndex={ !props.disabled ? -1 : undefined }
      css={ style }
      className="hide-text"
      { ...props }
    >
      close
    </button>
  );
};