import { useIsBetween, parallax } from '@immutabl3/alchemy';
import animation from './finalecontact.animation.json';
import ContactForm from '../../ContactForm';
import Typewriter from '../../Typewriter';
import { useStore } from '@immutabl3/store/react';
import style, {
  mask as maskStyle,
  paragraph as paragraphStyle,
} from './FinaleContact.style';

const { mask, main, head, subhead } = animation;

const FinaleContactMask = () => {
  return (
    <parallax.div
      beaker="chapter3"
      animation={ mask }
      css={ maskStyle }
    />
  );
};

const FinaleContactContent = () => {
  const hasEmail = !!useStore(['user', 'email']).length;
  const isVisible = useIsBetween('chapter3', main[0].timestamp, Infinity);

  return (
    <parallax.div
      beaker="chapter3"
      animation={ main }
      className={ isVisible ? 'interact' : undefined }
    >
      <ContactForm css={ style }>
        <parallax.p css={ paragraphStyle } className="strong text-display" beaker="chapter3" animation={ head }>
          <small data-active={ !hasEmail }>
            Insights like these are just the start.
          </small>
          <Typewriter active={ hasEmail }>
            You're on the list.
          </Typewriter>
        </parallax.p>
        <parallax.p css={ paragraphStyle } beaker="chapter3" animation={ subhead } className="text-display">
          <small data-active={ !hasEmail }>
            Get beta access to our seller platform and become one of the first.
          </small>
          <Typewriter active={ hasEmail }>
            You'll hear from Artica soon.
          </Typewriter>
        </parallax.p>
      </ContactForm>
    </parallax.div>
  );
};

export default function FinaleContact() {
  return (
    <>
      <FinaleContactMask />
      <FinaleContactContent />
    </>
  );
};