import { parallax } from '@immutabl3/alchemy';
import animation from './closing.animation';
import {
  top as topStyle,
  topMask as topMaskStyle,
  bottom as bottomStyle,
  bottomMask as bottomMaskStyle,
} from './Closing.style';

const {
  top,
  bottom,
  line1,
  line2,
  line3,
  maskTop,
  maskBottom,
} = animation;

export default function Closing() {
  return (
    <>
      <parallax.div css={ topMaskStyle } beaker="chapter2" animation={ maskTop } />
      <parallax.div css={ bottomMaskStyle } beaker="chapter2" animation={ maskBottom } />
      
      <parallax.p css={ topStyle } className="h3 strong text-display" beaker="chapter2" animation={ top }>
        Working smarter means trusting more, however.
      </parallax.p>
      <parallax.div css={ bottomStyle } beaker="chapter2" animation={ bottom }>
        <parallax.p beaker="chapter2" animation={ line1 }>
          <strong>Trust</strong> in a system you may not understand.
        </parallax.p>
        <parallax.p beaker="chapter2" animation={ line2 }>
          <strong>Trust</strong> in digital automation to analyze the data accurately and act on the insight effectively for you 24/7.
        </parallax.p>
        <parallax.p beaker="chapter2" animation={ line3 }>
          <strong>Trust</strong> that our years of commercial experience, deep data science expertise, and the powerful tools we've created from them can augment your gut instinct and help scale your business to stay in front of the competition.
        </parallax.p>
      </parallax.div>
    </>
  );
};