import { Frame } from '@immutabl3/alchemy';
import Question from './Question';
import Reticule from './Reticule';
import Answer from './Answer';
import style from './QuestionAnswer.style';
import { take } from '@immutabl3/utils';
import { useStore } from '@immutabl3/store/react';

export default function QuestionAnswer() {
  const qas = useStore(['user', 'insight', 'qa']);
  return (
    <div css={ style }>
      <Reticule />
      { take(qas, 3)
        .map(({ question, answer }, index) => (
          <Frame key={ index } flex="column" beaker="chapter2">
            <Question index={ index } question={ question } />
            <Answer index={ index } answer={ answer } />
          </Frame>
        )) }
    </div>
  );
};