import style from './Button.style';

export default function Button(props) {
  return (
    <button
      type="button"
      css={ style }
      { ...props }
    />
  );
};