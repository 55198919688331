import { parallax } from '@immutabl3/alchemy';
import style from './Torso.style';
import torso from './torso.animation.json';
import torsosub from './torsosub.animation.json';

export default function Torso() {
  return (
    <parallax.div css={ style } beaker="chapter1" animation={ torso }>
      <p className="text-display">
        Less than 1% of Amazon & D2C businesses achieve $10M of sales.
      </p>
      <parallax.p className="strong text-display" beaker="chapter1" animation={ torsosub }>
        But the Legends that do...
      </parallax.p>
    </parallax.div>
  );
};