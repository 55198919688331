import { useRef } from 'react';
import { useGetRef } from '@artica/hooks';
import { useStore } from '@immutabl3/store/react';
import Html from '../../Html';
import captionAnimations from './caption.animation.json';
import style, { svg as svgStyle } from './Caption.style';
import {
  Visible,

  useRAF,
  parallax,
  useResize,
} from '@immutabl3/alchemy';

const Caption = function({ index }) {
  const stateRef = useRef({});
  const lineRef = useRef();
  const captionRef = useRef();
  const animation = captionAnimations[index];
  const vectorRef = useGetRef(`questionmark${index}`);
  const question = useStore(['user', 'insight', 'qa', index, 'question']);
  const targetRef = useRef(null);
  if (!targetRef.current) targetRef.current = { x: 0, y: 0 };

  useRAF(() => {
    if (!vectorRef?.current) return;

    const { x, y } = vectorRef.current;
    if (!x || !y) return;
    
    const { x: x2, y: y2, offset } = targetRef.current;
    if (!x2 || !y2) return;
    
    const ox2 = x2 + offset;
    const { current: state } = stateRef;
    
    if (
      state.x === x &&
      state.y === y && 
      state.x2 === x2 &&
      state.y2 === y2 &&
      state.ox2 === ox2
    ) return;

    const line = lineRef.current;
    line.setAttribute('x1', x);
    line.setAttribute('y1', y);
    line.setAttribute('x2', ox2);
    line.setAttribute('y2', y2);

    state.x === x;
    state.y === y; 
    state.x2 === x2;
    state.y2 === y2;
    state.ox2 === ox2;
  }, [vectorRef]);

  useResize(({ width, height }) => {
    const { width: boxWidth, x, y } = captionRef.current.getBoundingClientRect();
    targetRef.current.x = width < 768 ? x + boxWidth : x;
    targetRef.current.offset = width < 768 ? -0.5 : 0.5;
    targetRef.current.y = y;

    const { current: state } = stateRef;
    if (state.width === width && state.height === height) return;
    
    lineRef.current.parentElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
    state.width = width;
    state.height = height;
  });

  return (
    <>
      <parallax.svg
        className="no-interact"
        css={ svgStyle }
        beaker="chapter1"
        animation={ animation }
      >
        <line ref={ lineRef } />
      </parallax.svg>
      <parallax.div
        className="no-interact"
        css={ style }
        beaker="chapter1"
        animation={ animation }
      >
        <Html ref={ captionRef }>
          { question }
        </Html>
      </parallax.div>
    </>
  );
};

export default function CaptionCapture({ index }) {
  return (
    <Visible beaker="chapter1" animation={ captionAnimations[index] }>
      <Caption index={ index } />
    </Visible>
  );
};