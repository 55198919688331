import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  p {
    width: 12rem;
    margin-left: auto;
    margin-right: auto;

    &:first-of-type {
      margin-top: 10.5em;
    }
    &:nth-of-type(2) {
      line-height: 1.25;
    }
    &:last-of-type {
      margin-top: auto;
      margin-bottom: 5em;
      line-height: 1.25;

      @media ${mq.md} {
        width: var(--display-width-xl);
      }
    }

    @media ${mq.sm} {
      width: var(--display-width);
    }
  }
`;