import { Frame } from '@immutabl3/alchemy';
import Intro from './Intro';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

export default function Chapter1Preselection() {
  return (
    <>
      <Frame flex="column" beaker="chapter1">
        <Intro />
      </Frame>
      <Frame flex="column" beaker="chapter1">
        <Step1 />
      </Frame>
      <Frame flex="column" beaker="chapter1">
        <Step2 />
      </Frame>
      <Frame flex="column" beaker="chapter1">
        <Step3 />
      </Frame>
      <Frame flex="column" beaker="chapter1">
        <Step4 />
      </Frame>
    </>
  );
};