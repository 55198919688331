import { css } from '@emotion/react';

export default css`
  position: relative;
  width: 100%;
  height: 100%;
  flex-shrink: 1;

  &:before {
    content: '';
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 0.4);
    scale: 1 1.2;
    transition:
      background-color 300ms ease,
      scale 300ms ease;
  }

  &:first-of-type {
    &:before {
      border-radius:
        calc(var(--scroll-bar-height, 3px) / 2)
        0
        0
        calc(var(--scroll-bar-height, 3px) / 2);
    }
  }
  &:last-of-type {
    &:before {
      border-radius:
        0
        calc(var(--scroll-bar-height, 3px) / 2)
        calc(var(--scroll-bar-height, 3px) / 2)
        0;
    }
  }

  &[data-active="true"] {
    &:before {
      background-color: var(--color-artica-snow);
    }
  }

  &:not([data-active="true"]) {
    cursor: pointer;
    
    &:hover {
      &:before {
        background-color: var(--color-artica-ocean-blue);
        scale: 1 1.5;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 3.125rem;
    translate: 0 -50%;
  }
`;