import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  white-space: nowrap;
  translate: -50% 100%;

  button {
    margin: 0 auto;

    &:first-of-type {
      margin-bottom: 1rem;
    }
    &:last-of-type {
      color: var(--color-artica-snow);
      
      @media ${mq.md} {
        display: none;
      }
    }
  }

  &[data-active="true"] {
    opacity: 1;
  }

  @media ${mq.sm} {
    translate: -50% 150%;
  }
`;

export const container = css`
  opacity: 0;
  transition: opacity 500ms ease;
  will-change: opacity;

  &[data-active="true"] {
    opacity: 1;
  }
`;