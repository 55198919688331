import { parallax } from '@immutabl3/alchemy';
import step1 from './step1.animation.json';
import style from './Step1.style';

export default function Step1() {
  return (
    <parallax.div css={ style } beaker="chapter1" animation={ step1 }>
      <p className="text-display">
        You need a partner that helps you work smarter in addition to working harder.
      </p>
    </parallax.div>
  );
};