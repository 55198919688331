import { css } from '@emotion/react';

export default css`
  position: relative;
  
  p {
    position: absolute;
    top: 0;
    left: 0;
  }
`;