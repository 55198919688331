import { css, keyframes } from '@emotion/react';
import articaLogoTile from '../../../../public/images/artica-logo-tile.png';

const anim = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
`;

export default css`
  position: absolute;
  top: 50%;
  left: 50%;

  width: calc(var(--radius) * 2);
  aspect-ratio: 1;

  border-radius: 50%;
  border: 2px solid var(--color-artica-snow);
  
  translate: -50% -50%;

  // dot
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 7.5%;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: var(--color-artica-snow);
    translate: -50% -50%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 175%;
    aspect-ratio: 1;
    border-radius: 50%;
    background-repeat: repeat;
    background-image: url(${articaLogoTile});
    translate: -50% -50%;
    opacity: 0.45;
    mix-blend-mode: overlay;
    mask: radial-gradient(
      50% 50% at 50% 50%,
      rgba(217, 217, 217, 0) 34.37%,
      #D9D9D9 48.96%,
      rgba(217, 217, 217, 0) 100%
    );
    animation: ${anim} 60s linear infinite;
  }
`;