import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  --height: 11.25rem;

  display: none;
  position: fixed;
  z-index: var(--z-signup);
  bottom: 0;
  left: 1rem;
  width: 30vw;
  max-width: 30rem;
  height: var(--height);
  overflow: hidden;
  line-height: 1.2;
  pointer-events: none;
  translate: 0 0 1px;

  @media ${mq.md} {
    display: block;
  }

  > div {
    height: var(--height);
    padding: 1rem 1.5rem;
    opacity: 1;
    pointer-events: auto;
    background-color: hsl(
      var(--color-artica-dark-wave-h)
      var(--color-artica-dark-wave-s)
      var(--color-artica-dark-wave-l)
      / 0.1
    );
    translate: 0 calc(var(--height) / 2);

    will-change:
      opacity,
      translate,
      background-color;

    transition:
      opacity 300ms ease,
      translate 300ms ease,
      background-color 300ms ease;

    &:hover,
    &:focus-within {
      opacity: 1;
      translate: 0 0;
      background-color: var(--color-artica-ocean-blue);
    }

    > button {
      position: absolute;
      top: 0;
      right: 0;
      color: var(--color-artica-snow);
    }
  }

  &[data-hidden="true"] {
    > div {
      &,
      &:hover,
      &:focus-within {
        opacity: 0;
        translate: 0 100%;  
        pointer-events: none;  
      }
    }
  }
`;