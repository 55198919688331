import style from './Mask.style';
import { parallax } from '@immutabl3/alchemy';
import animation from './intermission1.animation.json';

export default function Intermission1Mask() {
  return (
    <parallax.div
      css={ style }
      animation={ animation }
      data-color="light"
      className="no-interact"
    />
  );
};