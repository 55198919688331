import Opener from './opener';
import Gateway from './gateway';
import Preselection from './preselection';
import Selection from './selection';
import Pathway from './pathway';
import Outro from './outro';

export default function Chapter1() {
  return (
    <>
      <Opener />
      <Gateway />
      <Preselection />
      <Selection />
      <Pathway />
      <Outro />
    </>
  );
};