import { useEffect, useRef } from 'react';
import { window, restoreScroll } from '@immutabl3/alchemy';
import { isProd } from '@artica/constants';
import { useStore } from '@immutabl3/store/react';
import { isSafari, isMobile } from '@immutabl3/browser-environment';

// NOTE: in production always start at the top
// this will prevent multiple issues:
// * being able to miss a gateway due to rounding errors
// * chugging the browser after load due to the amount of webgl assets
// * preventing a camera race condition from being met e.g. 2 cameras asserting themselves in the scene
// ...etc...

export const useScrollLock = function() {
  const loaded = useStore(['loaded']);
  const sceneloaded = useStore(['sceneloaded']);
  const isLoaded = loaded && sceneloaded;

  useEffect(() => {
    if (isLoaded && !isProd) {
      window.resume();
      restoreScroll();
    } else {
      window.pause();
    }
  }, [isLoaded]);

  return null;
};

export const useVhShiv = function() {
  const ref = useRef();
  
  useEffect(() => {
    const needed = isMobile() && isSafari();
    if (!needed) return;
    
    const onResize = () => {
      if (!ref.current) return;
      ref.current.style.setProperty('--vh', `${globalThis.innerHeight}px`);
    };

    onResize();
    globalThis.addEventListener('resize', onResize);
    return () => {
      globalThis.removeEventListener('resize', onResize);
    };
  }, []);

  return ref;
};