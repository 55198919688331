import { parallax } from '@immutabl3/alchemy';
import style from './Subhead.style';
import subhead from './subhead.animation.json';

const {
  subhead1,
  subhead2,
  subhead3,
} = subhead;

export default function Subhead() {
  return (
    <div css={ style }>
      <parallax.p beaker="chapter1" animation={ subhead1 } className="text-display">
        Through intelligent insights so your eCommerce brand can accelerate & grow
      </parallax.p>
      <parallax.p beaker="chapter1" animation={ subhead2 } className="text-display">
        By revealing the smartest path to navigate the storms
      </parallax.p>
      <parallax.p beaker="chapter1" animation={ subhead3 } className="text-display">
        Using deep marketing & product data so you summit before anyone
      </parallax.p>
    </div>
  );
};