import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  --height: 23.125rem;
  
  flex-grow: 0;
  width: var(--display-width);
  margin: 0 auto;

  button {
    display: block;
    width: 100%;
    pointer-events: auto;
    text-align: start;
  }

  @media ${mq.sm} {
    width: var(--display-width-l);
  }
  @media ${mq.md} {
    max-width: var(--display-width-l);
  }
  @media ${mq.md} {
    width: var(--display-width-m-flex);
    max-width: none;
  }
`;

export const picker = css`
  transition: scale 300ms ease-in;
`;

export const pickerCollapsed = css`
  scale: 1 0;
`;

export const button = css`
  transition: translate 300ms ease-in;

  span {
    display: flex;
    position: absolute;
    aspect-ratio: 1 / 1;
    top: 50%;
    right: 0.5rem;
    width: 2.625rem;
    border-radius: 10%;
    opacity: 0;
    background-color: hsl(0deg 0% 85% / 0.16);
    translate: 0 -50%;
    transition: opacity 300ms ease-in;
  }
    svg {
      aspect-ratio: 1 / 1;
      width: 45%;
      margin: auto;
    }
`;

export const buttonCollapsed = css`
  translate: 0 calc((var(--height, 20rem) - 100%) * -1);
  
  span {
    opacity: 1;
  }
`;