import animation from './intermission.animation.json';
import style, { prompt as promptStyle } from './Intermission.style';
import ScrollPrompt from '../ScrollPrompt';
import {
  parallax,
} from '@immutabl3/alchemy';

export default function Intermission() {
  return (
    <div css={ style }>
      <parallax.p className="strong" beaker="intermission2" animation={ animation.message }>
        See the future.
      </parallax.p>
      <parallax.div
        beaker="intermission2"
        animation={ animation.prompt }
        css={ promptStyle }
      >
        <ScrollPrompt>
          Scroll
        </ScrollPrompt>
      </parallax.div>
    </div>
  );
};