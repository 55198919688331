import { parallax } from '@immutabl3/alchemy';
import intro from './intro.animation.json';
import style from './Intro.style';

export default function Intro() {
  return (
    <parallax.div css={ style } beaker="chapter1" animation={ intro }>
      <p className="text-display">
        That's where the technology comes in, something that intelligently parses through the data and then acts on it in ways humans can't do on their own.
      </p>
      <p className="strong text-display">  
        And in your specific category of business...
      </p>
    </parallax.div>
  );
};