import LocationHead from '../shared/LocationHead';
import getLayoutProps from '../shared/getLayoutProps';
import MetaHead from '../shared/MetaHead';
import Main from '../components/Main';
import Journey from '../components/Journey';
import SignUp from '../components/SignUp';
import { Global } from '@emotion/react';
import style from '../components/Home.style';

export default function Home(props) {
  return (
    <>
      <MetaHead { ...props } />
      <LocationHead title="Home" />
      <Global styles={ style } />
      <Main>
        <Journey
          StoreContext={ props.StoreContext }
          SignalContext={ props.SignalContext }
          AlchemyContext={ props.AlchemyContext }
        />
      </Main>
      <SignUp />
    </>
  );
};

export const getStaticProps = getLayoutProps;