import { useState, useEffect, useCallback } from 'react';

export const useCarousel = items => {
  const [activeIndex, setActiveIndex] = useState(0);
  
  const onPrevious = useCallback(() => {
    const min = 0;
    const nextIndex = activeIndex === min ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }, [activeIndex, items]);

  const onNext = useCallback(() => {
    const max = items.length - 1;
    const nextIndex = activeIndex === max ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }, [activeIndex, items]);

  const onSelect = useCallback(index => {
    setActiveIndex(index);
  }, [activeIndex, items]);

  useEffect(() => {
    return () => {
      setActiveIndex(0);
    };
  }, [items]);

  return {
    onPrevious,
    onNext,
    onSelect,
    index: activeIndex,
  };
};