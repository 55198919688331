export default function IconEdit(props) {
  return (    
    <svg focusable={ false } viewBox="0 0 19 19" fill="none" { ...props }>
      <path d="M13.3415 1.97252L11.9736 3.34037L15.6674 7.03416L17.0353 5.66631C17.3166 5.38502 17.3166 4.92561 17.0353 4.64432L14.3641 1.97254C14.0828 1.68754 13.6226 1.68754 13.3413 1.97254L13.3415 1.97252Z" fill="white"/>
      <path d="M13.0721 1.70272C12.6164 2.15842 12.1599 2.61487 11.7042 3.07057C11.5595 3.2153 11.5595 3.4617 11.7042 3.60643C12.7797 4.68186 13.855 5.75723 14.9304 6.83263C15.0863 6.98848 15.2422 7.14434 15.398 7.3002C15.5427 7.44492 15.7892 7.44492 15.9339 7.3002L17.0323 6.20177C17.1956 6.03849 17.393 5.87149 17.4999 5.66221C17.724 5.22135 17.629 4.71591 17.295 4.36265C16.5462 3.57593 15.7557 2.82334 14.9882 2.05585C14.8702 1.93785 14.7604 1.82058 14.6387 1.70629C14.1889 1.28472 13.5239 1.28472 13.0719 1.70258C12.7149 2.0373 13.2508 2.57242 13.6078 2.23844C13.9306 1.9386 14.2461 2.39059 14.4591 2.6036C14.8732 3.01774 15.2836 3.42816 15.6978 3.8423C16.0325 4.17703 16.3665 4.51101 16.6975 4.84203C16.8118 4.95632 16.9023 5.08175 16.8497 5.24874C16.8044 5.39718 16.6404 5.5189 16.5342 5.62131L15.3942 6.76131H15.9301C14.8546 5.68587 13.7793 4.61051 12.7039 3.53511C12.548 3.37925 12.3922 3.2234 12.2363 3.06754V3.6034C12.692 3.1477 13.1485 2.69125 13.6041 2.23555C13.9574 1.89266 13.4179 1.3568 13.072 1.70266L13.0721 1.70272Z" fill="white"/>
      <path d="M10.8748 4.43848L2.6366 12.6805L1.75488 17.2367L6.30006 16.401L14.5689 8.13219L10.8748 4.43848Z" fill="white"/>
      <path d="M10.6096 4.17311C10.1042 4.67854 9.59508 5.18767 9.08964 5.69311C8.02534 6.75741 6.96544 7.81731 5.90125 8.8815C4.95496 9.82779 4.00496 10.7778 3.05866 11.7241C2.84195 11.9408 2.62151 12.1538 2.4085 12.3742C2.21107 12.5836 2.20737 12.9175 2.15394 13.1877C1.90308 14.4761 1.65593 15.7601 1.40508 17.0485C1.39765 17.0789 1.39394 17.1093 1.38578 17.1398C1.32863 17.4359 1.55648 17.66 1.85335 17.6073C3.02379 17.3906 4.19415 17.1776 5.36455 16.9616C5.63025 16.9119 5.8967 16.8629 6.16612 16.8132C6.31456 16.7865 6.44742 16.7791 6.56913 16.6685C6.73612 16.52 6.88827 16.3493 7.04413 16.1935L9.71534 13.5223C10.8019 12.4357 11.8855 11.3521 12.9721 10.2655C13.5837 9.6539 14.2146 9.05719 14.8076 8.43004C14.815 8.42262 14.8269 8.41074 14.8343 8.40332C14.979 8.25859 14.979 8.01218 14.8343 7.86746C13.7588 6.79202 12.6835 5.71666 11.6081 4.64126C11.4522 4.4854 11.2964 4.32955 11.1405 4.17369C10.7947 3.82783 10.2551 4.36369 10.6047 4.70955C11.6801 5.78499 12.7555 6.86035 13.8309 7.93575C13.9867 8.09161 14.1426 8.24746 14.2984 8.40332V7.86746L11.4982 10.6677C10.0124 12.1535 8.52642 13.6395 7.04062 15.1253C6.70219 15.4637 6.36449 15.8014 6.02606 16.1398C6.08321 16.1057 6.1359 16.0753 6.19305 16.0411C4.86676 16.2846 3.54426 16.5273 2.21787 16.7707C2.02787 16.8048 1.83787 16.839 1.64787 16.8768C1.80373 17.0327 1.95958 17.1885 2.11544 17.3444C2.37373 16.0144 2.63201 14.6844 2.88658 13.3544C2.92443 13.1644 2.95857 12.9781 2.99642 12.7881C2.96228 12.8452 2.93185 12.8979 2.89771 12.9551L5.68691 10.1659C7.16534 8.68746 8.64673 7.20607 10.1251 5.72768C10.4635 5.38925 10.8013 5.05154 11.1397 4.71311C11.4915 4.36279 10.9556 3.82323 10.6098 4.17279L10.6096 4.17311Z" fill="white"/>
    </svg>
  );
};
