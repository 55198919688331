import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  p {
    width: var(--display-width-l);
    margin: auto auto 1rem auto;
    
    @media ${mq.sm} {
      width: var(--display-width-xl);
    }

    &:nth-of-type(2) {
      margin-top: -0.5rem;
    }
  }
`;