import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  margin: auto;
  
  p {
    @media ${mq.sm} {
      scale: 1.2;
    }
  }
`;