import Triangle from './icons/Triangle';
import style from './ScrollPrompt.style';

export default function ScrollPrompt({
  color,
  className,
  children,
}) {
  return (
    <div css={ style } className={ className }>
      <small>
        { children }
      </small>
      <Triangle
        down
        color={ color }
        style={ {
          '--triangle-width': '18px',
          '--triangle-height': '15px',
        } }
      />
    </div>
  );
};