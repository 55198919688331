import insightpicker from './insightpicker.animation.json';
import Picker from '../../Picker';
import Button from '../../Button';
import Edit from '../../icons/Edit';
import { useStore } from '@immutabl3/store/react';
import style, {
  picker as pickerStyle,
  pickerCollapsed as pickerCollapsedStyle,
  button as buttonStyle,
  buttonCollapsed as buttonCollapsedStyle,
} from './InsightPicker.style';
import {
  useInsightPicker,
} from './InsightPicker.hooks';
import {
  parallax,
} from '@immutabl3/alchemy';

const InsightPicker = () => {
  const {
    insights,
    
    isVisible,
    isDisabled,
    isCollapsed,
    hasSelected,
    
    onChange,
    onContinue,  

    defaultCategory,
    selectedCategory,
  } = useInsightPicker();

  return (
    <parallax.div
      css={ style }
      beaker="chapter1"
      animation={ insightpicker }
      className={ !isVisible ? 'no-interact' : undefined }
    >
      <Picker
        defaultValue={ defaultCategory.id }
        options={ insights }
        onChange={ onChange }
        disabled={ isDisabled }
        css={ [
          pickerStyle,
          isCollapsed && pickerCollapsedStyle,
        ] }
      />
      <div 
        css={ [
          buttonStyle,
          isCollapsed && buttonCollapsedStyle,
        ] }
      >
        <Button
          tabIndex={ isDisabled ? -1 : undefined }
          onClick={ onContinue }
        >
          { isCollapsed
            ? selectedCategory?.name
            : hasSelected ? 'Change Selection' : 'Continue' }
          <span>
            <Edit />
          </span>
        </Button>
      </div>
    </parallax.div>
  );
};
export default function InsightPickerContainer() {
  const insights = useStore(['insights']);
  if (!insights?.length) return null;
  return <InsightPicker />;
};