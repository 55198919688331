import { parallax } from '@immutabl3/alchemy';
import Html from '../../Html';
import style from './Question.style';
import sharedStyle from './QuestionAndAnswerShared.style';
import questionanswer from './questionanswer.animation';

export default function Question({ index, question }) {
  const animation = questionanswer.question[index];

  return (
    <parallax.div css={ [sharedStyle, style] } beaker="chapter2" animation={ animation }>
      <Html>
        { question }
      </Html>
    </parallax.div>
  );
};