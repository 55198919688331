import { useRef, useState, useCallback, useEffect } from 'react';

const measure = ref => ref.current.firstElementChild.getBoundingClientRect().height; 

const useHeight = ref => {
  const rowHeight = useRef(
    ref.current ? measure(ref) : 0
  );
  
  const onResize = useCallback(() => (
    rowHeight.current = measure(ref)
  ), []);

  useEffect(() => {
    globalThis.addEventListener('resize', onResize);
    return () => globalThis.removeEventListener('resize', onResize);
  }, []);

  return {
    rowHeight,
    measure: onResize,
  };
};

export const usePicker = (
  options,
  onChange,
  defaultValue,
) => {
  const ref = useRef();
  const { rowHeight, measure } = useHeight(ref);
  const [value, setValue] = useState(defaultValue);
  const curentValue = useRef();
  curentValue.current = value;

  const scrollTo = useCallback((value, isJump = false) => {
    const idx = options.findIndex(({ id }) => id === value.id);
    const top = Math.round((rowHeight.current * idx) + (rowHeight.current / 2));
    ref.current.scrollTo({
      top,
      left: 0,
      behavior: isJump ? undefined : 'smooth',
    });
  }, []);

  const onSelect = useCallback(valueId => {
    const value = options.find(({ id }) => id === valueId);
    scrollTo(value);
    setValue(value);
    onChange(value);
  }, [onChange]);

  const onScroll = useCallback(() => {
    const top = ref.current.scrollTop;
    const idx = ~~(top / rowHeight.current);
    const value = options[idx];
    
    // over & under scroll
    if (value === undefined) return;

    if (curentValue.current !== value) {
      curentValue.current = value;
      setValue(value);
      onChange(value);
    }
  }, [onChange, rowHeight]);

  useEffect(() => {
    measure();
    scrollTo(
      options.find(({ id }) => id === defaultValue),
      true // isJump
    );
  }, [defaultValue]);
  
  return {
    ref,
    value,
    onScroll,
    onSelect,
  };
};