import { useRef, useCallback, useState } from 'react';
import gateway from './gateway.animation.json';
import { useStore } from '@immutabl3/store/react';
import {
  useSignal,
} from '@artica/hooks';
import {
  window,
  useGate,
  useIsBetween,
  useConvertTimeToScroll,
} from '@immutabl3/alchemy';
import insightpicker from './insightpicker.animation.json';

const pickDefault = list => (
  list.find(({ isDefault }) => isDefault) || list[0]
);

export const useInsightPicker = function() {
  const signal = useSignal();
  const insights = useStore(['insights']);

  const userCategory = useStore(['user', 'category']);
  const userCategoryRef = useRef();
  userCategoryRef.current = userCategory ?? {};

  const [defaultCategory] = useState(
    userCategory?.id ? userCategory : pickDefault(insights),
  );

  const [selectedCategory, setCategory] = useState(defaultCategory);
  const selectedCategoryRef = useRef();
  selectedCategoryRef.current = selectedCategory;

  const [isCollapsed, setCollapsed] = useState(false);

  const onOpen = useCallback(() => {
    setCollapsed(false);
  }, []);

  const hasSelectedCategory = !!userCategory?.id;
  const shouldLock = !hasSelectedCategory;
  const { unlock } = useGate('chapter1', gateway.gate, shouldLock);
  const top = useConvertTimeToScroll('chapter1', gateway.unlock.timestamp);

  const onChange = useCallback(category => {
    setCategory(category);
  }, [signal]);

  const onContinue = useCallback(() => {
    signal.emit('user:category:select', selectedCategoryRef.current);
    
    setCollapsed(true);
    
    unlock();
    
    window.scrollTo({
      top,
      duration: gateway.unlock.duration * 1000,
    });
  }, [signal, unlock, top]);

  const isVisible = useIsBetween(
    'chapter1',
    insightpicker[0].timestamp,
    insightpicker[1].timestamp + insightpicker[1].duration,
  );

  return {
    isVisible,
    isCollapsed,
    isDisabled: isCollapsed || !isVisible,
    hasSelected: hasSelectedCategory,

    onChange,
    onContinue: isCollapsed ? onOpen : onContinue,   
    
    insights,
    defaultCategory,
    selectedCategory,
  };
};