import { css } from '@emotion/react';

export default css`
  position: relative;
  width: 3.125rem;
  aspect-ratio: 1 / 1;

  --close-color: currentColor;
  --close-color-active: var(--color-artica-neo-green);

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 50%;
    translate: -1px -50%;
    background-color: var(--close-color);
    transition: background-color 300ms ease;
  }

  &:before {
    rotate: -45deg;
  }
  &:after {
    rotate: 45deg;
  }

  &:hover,
  &:active,
  &:focus {
    &:before,
    &:after {
      background-color: var(--close-color-active);
    }
  }
`;