import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  display: flex;
  flex-direction: column;
  
  position: relative;
  max-width: var(--display-width);
  margin: auto;
  
  color: var(--color-artica-midnight);

  @media ${mq.lg} {
    max-width: var(--display-width-m-flex);
  }

  p {
    margin-bottom: 5rem;
  }
`;

export const prompt = css`
  margin: 0 auto;
`;