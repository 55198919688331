import ContactForm from '../ContactForm';
import Close from '../Close';
import { useSignUp } from './SignUp.hooks';
import style from './SignUp.style';

export default function SignUp() {
  const { isClosed, onDismiss } = useSignUp();

  return (
    <aside css={ style } data-hidden={ isClosed }>
      <div>
        <ContactForm disabled={ isClosed } />
        <Close onClick={ onDismiss } disabled={ isClosed } />
      </div>
    </aside>
  );
};