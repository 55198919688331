import { css, keyframes } from '@emotion/react';

const spinner = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export default css`
  --spinner-size: 1.3125rem;
  --spinner-speed: 2s;
  --spinner-stroke-size: 1px;

  display: inline-block;
  vertical-align: middle;

  width: var(--spinner-size);
  aspect-ratio: 1 / 1;
  
  border-top-color: var(--color-artica-snow);
  border-left-color: var(--color-artica-snow);
  border-bottom-color: var(--color-artica-snow);
  border-right-color: transparent;
  border-style: solid;
  border-width: var(--spinner-stroke-size);
  border-radius: 50%;

  filter: drop-shadow(0 0 0.25rem var(--color-artica-snow));

  animation: ${spinner} var(--spinner-speed) linear infinite;
`;
