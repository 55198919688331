import { parallax } from '@immutabl3/alchemy';
import intro from './intro.animation.json';
import style from './Intro.style';

export default function Intro() {
  return (
    <parallax.div css={ style } beaker="chapter1" animation={ intro }>
      <p className="strong center">
        But you need more.
      </p>
    </parallax.div>
  );
};