import { css } from '@emotion/react';

export default css`
  display: flex;
  width: 3.125rem;
  height: 3.125rem;
  position: absolute;
  z-index: 1;
  top: 50%;
  translate: 0 -50%;
  transition: opacity 300ms ease;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    aspect-ratio: 1 / 1;
    margin: auto;
  }
    svg {
      width: 1rem;
      filter: drop-shadow(0 0 0.5rem var(--color-artica-snow));    
    }

  &:first-of-type {
    left: -1.75rem;
    
    svg {
      rotate: 180deg;
      translate: -0.05rem 0;
    }
  }
  &:last-of-type {
    right: -1.75rem;

    svg {
      translate: 0.05rem 0;
    }
  }

  &[disabled] {
    opacity: 0.25;
  }
`;