import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  isolation: isolate;
  position: relative;
  width: 100vw;
  left: calc(var(--padding) * -1);
  margin: 0 0 1em 0;
`;

export const prompt = css`
  position: absolute;
  bottom: 0;
  left: 50%;
  translate: -50% 0;

  @media ${mq.sm} {
    bottom: 5em;
  }
`;

export const backdrop = css`
  position: absolute;
  width: 100vw;
  height: 120%;
  top: 50%;
  left: 50%;
  background-color: hsl(
    226deg
    20%
    6%
    / 0.6
  );
  translate: -50% -50%;
`;