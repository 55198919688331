import { Frame } from '@immutabl3/alchemy';
import Headline from './Headline';
import Loader from './Loader';
import ScrollToContinue from './ScrollToContinue';
import ButtonPrompt from './ButtonPrompt';
import Torso from './Torso';

export default function Chapter1Opener() {
  return (
    <>
      <Loader />
      <Frame flex="column" beaker="chapter1">
        <Headline />
        <ButtonPrompt />
        <ScrollToContinue />
      </Frame>
      <Frame flex="column" beaker="chapter1">
        <Torso />
      </Frame>
    </>
  );
};