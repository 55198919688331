import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  isolation: isolate;
  position: relative;
  width: var(--display-width);
  margin: auto;
  padding: 1.5rem 0 3rem;

  @media ${mq.sm} {
    width: var(--display-width-l);
  }
  @media ${mq.md} {
    width: var(--display-width-xl);
  }
`;