import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  position: absolute;
  inset: 0;
  background-image: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 30%,
    var(--color-artica-snow) 100%
  );

  @media ${mq.lg} {
    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 50%,
      var(--color-artica-snow) 100%
    );
  }
`;