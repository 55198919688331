import { css } from '@emotion/react';

export default css`
  position: absolute;
  top: 100%;
  padding-top: 2rem;
  color: rgb(255 255 255 / 0.35);
  line-height: 1.1;
  font-size: 0.5rem;

  i {
    padding-left: var(--pad, 0.25em);
    &:nth-of-type(1) { --pad: 0.75em; }
    &:nth-of-type(2) { --pad: 0.5em; }
    &:nth-of-type(3) { --pad: 0.25em; }
  }
`;
