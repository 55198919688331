import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  position: absolute;
  left: 50%;
  bottom: 8%;
  opacity: 0;
  translate: -50% 0;
  transition: opacity 500ms ease;

  @media ${mq.sm} {
    bottom: 22.5%;
  }

  small {
    position: absolute;
    bottom: 100%;;
    left: 50%;
    letter-spacing: 0.05em;
    translate: -50% -0.5rem;
  }

  &[data-loaded="false"] {
    opacity: 1;
  }
`;

export const container = css`
  z-index: calc(var(--z-mask) + 1);
`;