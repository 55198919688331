import onBlurUpdate from '../utils/onBlurUpdate';
import animations from './valueproposition.animation.json';

export default {
  first: animations.first,
  second: animations.second,
  last: animations.last,
  firstMask: animations.firstMask.map(anim => ({ ...anim, update: onBlurUpdate })),
  secondMask: animations.secondMask.map(anim => ({ ...anim, update: onBlurUpdate })),
  lastMask: animations.lastMask.map(anim => ({ ...anim, update: onBlurUpdate })),
};