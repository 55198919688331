import style from './ButtonGroupNav.style';
import Item from './ButtonGroupNavItem';

export default function ButtonGroupNav({
  items,
  active,
  onSelect,
}) {
  return (
    <ol css={ style } className="interact">
      { items.map((_, idx) => (
        <Item
          key={ idx }
          index={ idx }
          active={ active }
          onSelect={ onSelect }
        />
      )) }
    </ol>
  );
};