import { css } from '@emotion/react';

export default css`
  position: relative;
  white-space: nowrap;

  small {
    position: absolute;
    bottom: 100%;
    left: 50%;
    letter-spacing: 0.05em;
    translate: -50% -0.25rem;
  }
`;