import { css } from '@emotion/react';

export default css`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  opacity: 1;
  translate: -50% 0;
  transition: opacity 500ms ease;
  pointer-events: auto;

  &[data-unlocked="true"] {
    opacity: 0;
  }
`;
