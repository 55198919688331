import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  margin: auto auto 0 auto;

  @media ${mq.sm} {
    margin-bottom: 5rem;
  }
  @media ${mq.md} {
    margin-bottom: 0;
  }
`;