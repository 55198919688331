import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  p {
    width: 12.5rem;
    margin-left: auto;
    margin-right: auto;

    &:nth-of-type(1) {
      margin-top: 9.5em;
      line-height: 1.25;
    }
    &:nth-of-type(2) {
      width: auto;
     
      @media ${mq.lg} {
        width: var(--display-width-xl);
      }
    }
    &:nth-of-type(3) {
      margin-top: auto;
    }

    @media ${mq.sm} {
      width: var(--display-width);
      &:nth-of-type(3) {
        margin-bottom: 3em;
      }
      &:nth-of-type(4) {
        margin-bottom: 6em;
      }
    }
  }
`;