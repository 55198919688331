import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  justify-content: center;

  > p {
    margin: 0 auto 5rem;

    @media ${mq.lg} {
      margin: 0 auto 7rem;
    }
  }
`;