import { parallax } from '@immutabl3/alchemy';
import headline from './headline.animation.json';
import Subhead from './Subhead';
import style from './Headline.style';

export default function Headline() {
  return (
    <div css={ style }>
      <parallax.div beaker="chapter1" animation={ headline }>
        <h1 className="text-display">
          We help your business become legendary
        </h1>
        <Subhead />
      </parallax.div>
    </div>
  );
};