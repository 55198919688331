import { css } from '@emotion/react';

export default css`
  --scroll-bar-height: 3px;

  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  gap: 1px;
  bottom: 0.5rem;
  left: 0;
  width: 100%;
  height: var(--scroll-bar-height);
`;