import animation from './intermission.animation.json';
import style, { prompt as promptStyle } from './Intermission.style';
import ScrollPrompt from '../ScrollPrompt';
import {
  parallax,
} from '@immutabl3/alchemy';

export default function Intermission() {
  return (
    <div css={ style }>
      <parallax.p className="strong" beaker="intermission1" animation={ animation.message }>
        How can you be?
      </parallax.p>
      <parallax.div
        beaker="intermission1"
        animation={ animation.prompt }
        css={ promptStyle }
      >
        <ScrollPrompt color="#000">
          Scroll
        </ScrollPrompt>
      </parallax.div>
    </div>
  );
};