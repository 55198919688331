import animation from './buttonPrompt.animation.json';
import { useStore } from '@immutabl3/store/react';
import circleButtonStyle from '../../CircleButton.style';
import style, { container as containerStyle } from './ButtonPrompt.style';
import {
  useOnStart,
  useSignUp,
} from './ButtonPrompt.hooks';
import {
  parallax,
  useConvertTimeToScroll,
} from '@immutabl3/alchemy';

const ContinueButton = () => {
  const top = useConvertTimeToScroll(animation.target);
  const onStart = useOnStart(top, animation.duration);

  return (
    <button
      css={ circleButtonStyle }
      onPointerDown={ onStart }
      beaker="chapter1"
      className="center interact"
      animation={ animation.button }
      aria-label="get started"
    >
      start your<br/>
      journey
    </button>
  );
};

const SignUpButton = () => {
  const onSignUp = useSignUp();

  return (
    <button
      aria-label="sign up"
      className="center interact"
      onPointerDown={ onSignUp }
    >
      <small>
        Sign up for the Artica platform
      </small>
    </button>
  );
};

export default function ButtonPrompt() {
  const loaded = useStore(['loaded']);
  const sceneloaded = useStore(['sceneloaded']);
  const isLoaded = loaded && sceneloaded;

  return (
    <div
      css={ containerStyle }
      data-active={ isLoaded }
    >
      <parallax.div
        beaker="chapter1"
        animation={ animation.button }
        css={ style }
      >
        <ContinueButton />
        <SignUpButton />
      </parallax.div>
    </div>
  );
};