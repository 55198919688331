import outroAnimations from './outro.animation.json';
import style from './Outro.style';
import { parallax } from '@immutabl3/alchemy';

const { container, line2 } = outroAnimations;

export default function Outro() {
  return (
    <div css={ style }>
      <parallax.p beaker="chapter1" animation={ container } className="text-display">
        How can you work smarter if doing so requires you to work harder?
      </parallax.p>
      <div>
        <parallax.p beaker="chapter1" animation={ line2 } className="text-display">
          The competition is always on, every minute of every day.
        </parallax.p>
      </div>
    </div>
  );
};