import { Frame, parallax } from '@immutabl3/alchemy';
import style from './Conclusion.style';
import animation from './conclusion.animation.json';

const { head, subhead, tail } = animation;

export default function Conclusion() {
  return (
    <Frame flex="column" css={ style } beaker="chapter3">
      <parallax.p className="strong center" beaker="chapter3" animation={ head }>
        Now, your business isn't just good, it's great.
      </parallax.p>
      <parallax.p beaker="chapter3" className="center" animation={ subhead }>
        It's growing in profit, scaling in size, and success has become more cyclical.
      </parallax.p>
      <parallax.p beaker="chapter3" className="center" animation={ tail }>
        Artica helps you create an 'innovate-then-automate' loop, one that converges exponentials as more data is produced, analyzed, and enacted.
      </parallax.p>
    </Frame>
  );
};