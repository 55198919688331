import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  position: relative;
  z-index: 1;
  width: var(--display-width);
  margin: 5rem auto auto auto;

  @media ${mq.sm} {
    width: var(--display-width-l);
  }
  @media ${mq.lg} {
    width: var(--display-width-xl);
  }
`;

export const mask = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 25vh;
  background-image: linear-gradient(
    180deg,
    rgb(0 0 0 / 0.25) 0%,
    rgb(0 0 0 / 0.05) 100%
  );
  mask: linear-gradient(
    to top,
    transparent 0%,
    black 20%
  );
`;
