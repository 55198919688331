import Outro from './Outro';
import Glow from './Glow';
import { Frame } from '@immutabl3/alchemy';

export default function Chapter1Outro() {
  return (
    <Frame flex="column" beaker="chapter1">
      <Glow />
      <Outro />
    </Frame>
  );
};