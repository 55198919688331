import { css } from '@emotion/react';

export default css`
  position: absolute;
  z-index: var(--z-mask);
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
  background-color: hsl(
    var(--color-artica-coal-h)
    var(--color-artica-coal-s)
    var(--color-artica-coal-l)
    / 0.4
  );
  translate: 0 0 1px;
`;

export const bar = css`
  position: absolute;
  inset: 0;
  background-color: hsl(
    var(--color-artica-neo-green-h)
    var(--color-artica-neo-green-s)
    var(--color-artica-neo-green-l)
    / 0.2
  );
  will-change: scale;
  scale: 1 var(--perc, 0);
  transform-origin: 0 0;
`;