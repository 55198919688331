import style from './ButtonNav.style';
import Chevron from '../icons/Chevron';
import circleButtonStyle from '../CircleButton.style';

export default function ButtonNav({ items, onPrevious, onNext, active }) {
  return (
    <nav className="interact">
      <button
        aria-label="previous"
        css={ style }
        disabled={ active === 0 }
        onClick={ onPrevious }
      >
        <span css={ circleButtonStyle }>
          <Chevron />
        </span>
      </button>
      <button
        aria-label="next"
        css={ style }
        disabled={ active === items.length - 1 }
        onClick={ onNext }
      >
        <span css={ circleButtonStyle }>
          <Chevron />
        </span>
      </button>
    </nav>
  );
};