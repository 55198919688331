import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  width: var(--display-width);
  margin: auto;

  @media ${mq.md} {
    width: var(--display-width-l);
  }
  
  div {
    &[data-disabled="true"] {
      > small {
        padding-top: 1rem;
        background-color: transparent;
      }
    }
  }
`;

export const paragraph = css`
  position: relative;

  &:nth-of-type(1) {
    margin-bottom: 0.5rem;
  }

  > small {
    transition: opacity 300ms ease;
    font-size: 1rem;
    
    &:nth-of-type(2) {
      position: absolute;
      top: 0.2rem;
      left: 0;
    }

    &[data-active="false"] {
      opacity: 0;
    }
  }
`;

export const mask = css`
  position: absolute;
  inset: 0;
  will-change: opacity;
  background-color: hsl(
    226deg
    20%
    16%
    / 0.7
  );
`;