import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  position: relative;
  max-width: var(--display-width);
  margin: auto auto 0 auto;
  padding: 0 0 5rem;
  
  @media ${mq.lg} {
    max-width: var(--display-width-m-flex);
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
  }

  p {
    color: var(--color-artica-midnight);
  }
`;