import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  width: var(--display-width);
  margin: auto auto 0 auto;

  @media ${mq.sm} {
    width: var(--display-width-l);
    margin-bottom: 4rem;
  }
  @media ${mq.md} {
    margin-bottom: 0;
  }
  @media ${mq.lg} {
    width: var(--display-width-m-flex);
  }
`;