import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  width: var(--display-width);

  @media ${mq.sm} {
    width: var(--display-width-l);
  }
  @media ${mq.lg} {
    width: var(--display-width-m-flex);
    margin-top: 5rem;
  }  
`;