import { parallax } from '@immutabl3/alchemy';
import animation from './scrollToContinue.animation.json';
import ScrollPrompt from '../../ScrollPrompt';
import style from './ScrollToContinue.style';

export default function ScrollToContinue() {
  return (
    <parallax.div beaker="chapter1" animation={ animation }>
      <ScrollPrompt css={ style }>
        Scroll to continue
      </ScrollPrompt>
    </parallax.div>
  );
};