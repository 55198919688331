import { Frame, parallax } from '@immutabl3/alchemy';
import style from './Bridge.style';
import animation from './bridge.animation.json';

const { head, subhead, tail, subtail } = animation;

export default function Bridge() {
  return (
    <Frame flex="column" css={ style } beaker="chapter3">
      <parallax.p beaker="chapter3" className="center" animation={ head }>
        That loop accelerates your business success, makes it more predictable and sustainable as you know more about your customer, your competitors, and the market.
      </parallax.p>
      <parallax.p className="strong center" beaker="chapter3" animation={ subhead }>
        And it does the work for you.
      </parallax.p>
      <parallax.p className="strong center" beaker="chapter3" animation={ tail }>
        You're not working harder, you're working smarter.
      </parallax.p>
      <parallax.p className="h3 center" beaker="chapter3" animation={ subtail }>
        In short, you win.
      </parallax.p>
    </Frame>
  );
};