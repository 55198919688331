import { useCallback } from 'react';
import style from './ButtonGroupNavItem.style';

export default function ButtonGroupNavItem({
  active,
  index,
  onSelect,
}) {
  const onClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    onSelect(index);
  }, [index]);

  return (
    <li
      css={ style }
      aria-label={ `insight ${index + 1}` }
      data-active={ active === index }
      onClick={ onClick }
    >
      <span className="visually-hidden">
        { index + 1 }
      </span>
    </li>
  );
};