import Headline from './Headline';
import Caption from './Caption';

export default function Chapter1Pathway() {
  return (
    <>
      <Headline />
      <Caption index={ 0 } />
      <Caption index={ 1 } />
      <Caption index={ 2 } />
    </>
  );
};