import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  width: var(--display-width-s);
  margin: auto auto 10vh auto;

  @media ${mq.lg} {
    width: var(--display-width-l);
  }
`;