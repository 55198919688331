import { useEffect, useRef } from 'react';
import { useSignal } from '@artica/hooks';
import { useStore } from '@immutabl3/store/react';

export const useSetRef = function(key) {
  const mesh = useRef(null);
  const signal = useSignal();

  useEffect(() => {
    signal.emit('references:add', key, mesh);
    return () => {
      signal.emit('references:remove', key, mesh);
    };
  }, [key, mesh, signal]);

  return mesh;
};

export const useGetRefs = function(key) {
  return useStore(['references', key]) ?? [];
};

export const useGetRef = function(key) {
  return useGetRefs(key)[0];
};