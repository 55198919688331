import { parallax } from '@immutabl3/alchemy';
import step4 from './step4.animation.json';
import style from './Step4.style';

export default function Step3() {
  return (
    <parallax.div css={ style } beaker="chapter1" animation={ step4 }>
      <p className="text-display">
        But that knowledge is useless if you can't act on it, if it can't tell you what changes to make or courses to alter to keep your business moving forward.
      </p>
    </parallax.div>
  );
};