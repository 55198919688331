import { css } from '@emotion/react';

export default css`    
  flex-shrink: 0;
  width: 100%;
  padding: 0 2rem;
  translate: var(--offset) 0;
  transition: translate 300ms ease;

  p {
    margin: 0;
    font-size: 0.875rem;
  }
`;