import { parallax } from '@immutabl3/alchemy';
import animation from './finaleinsights.animation';
import backdropAnimation from './finaleinsights.backdrop.animation';
import { useStore } from '@immutabl3/store/react';
import Carousel from '../../Carousel';
import ScrollPrompt from '../../ScrollPrompt';
import style, {
  prompt as promptStyle,
  backdrop as backdropStyle,
} from './FinaleInsights.style';

export default function FinaleInsights() {
  const insights = useStore(['user', 'insight', 'insights']);

  return (
    <>
      <div css={ style }>
        <parallax.div
          css={ backdropStyle }
          beaker="chapter3"
          animation={ backdropAnimation }
        />
        <parallax.div
          beaker="chapter3"
          animation={ animation }
        >
          { insights.length && <Carousel items={ insights } /> }
        </parallax.div>
      </div>
      <parallax.div
        beaker="chapter3"
        animation={ animation }
      >
        <ScrollPrompt css={ promptStyle }>
          Scroll
        </ScrollPrompt>
      </parallax.div>
    </>
  );
};