import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  width: var(--display-width);
  margin: auto;
  
  @media ${mq.lg} {
    width: var(--display-width-m-flex);
  }
  
  > div {
    translate: 0 -100%;
   
    @media ${mq.md} {
      translate: 0 -50%;
    }
  }
`;