import Head from 'next/head';
import { DOMAIN_URL, SITE_NAME } from '@artica/constants';

interface Input {
  title: string;
  pathname?: string;
};

export default function LocationHead({
  title,
  pathname = '/',
}: Input) {
  return (
    <Head>
      <title>{ title ? `${title} | ${SITE_NAME}` : SITE_NAME }</title>
      
      <link href={ `${DOMAIN_URL}${pathname.startsWith('/') ? pathname : `/${pathname}`}` } rel="canonical" />

      <meta name="twitter:title" content={ title ? `${title} | ${SITE_NAME}` : SITE_NAME } />
    </Head>
  );
};