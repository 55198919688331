import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  --radius: 4.65rem;
  --gap: 1.55;
  
  @media ${mq.sm} {
    --gap: 2;
  }
  @media ${mq.md} {
    --gap: 1.55;
  }
  @media ${mq.lg} {
    --gap: 2;
  }
`;