import Conclusion from './Conclusion';
import Bridge from './Bridge';
import Finale from './Finale';

export default function Chapter1Opener() {
  return (
    <>
      <Conclusion />
      <Bridge />
      <Finale />
    </>
  );
};