import { Frame } from '@immutabl3/alchemy';
import Gateway from './Gateway';
import Telescope from './Telescope';

export default function Chapter1Gateway() {
  return (
    <>
      <Frame flex="column" beaker="chapter1">
        <Gateway />
      </Frame>
      <Frame flex="column" beaker="chapter1">
        <Telescope />
      </Frame>
    </>
  );
};