import { css } from '@emotion/react';

export default css`
  color: var(--color-artica-midnight);
  background-color: hsl(80deg 10% 80% / 0.7);
  translate: -50% calc(((var(--radius) * var(--gap)) * -1) + -100%);

  &:after {
    top: 100%;
    width: 1px;
    height: calc(var(--radius) * var(--gap));
  }
`;