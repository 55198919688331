import style from './JourneyContainer.style';
import { useScrollLock, useVhShiv } from './Journey.hooks';

export default function JourneyContainer({ children }) {
  useScrollLock();
  
  return (
    <div
      css={ style }
      ref={ useVhShiv() }
    >
      { children }
    </div>
  );
};