import { parallax } from '@immutabl3/alchemy';
import step3 from './step3.animation.json';
import style from './Step3.style';

export default function Step3() {
  return (
    <parallax.div css={ style } beaker="chapter1" animation={ step3 }>
      <p className="h3 center">
        In the business<br />
        world, knowledge<br />
        is data.
      </p>
    </parallax.div>
  );
};