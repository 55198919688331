import { forwardRef } from 'react';
import { isString } from '@immutabl3/utils';

export default forwardRef(function Html({
  as: Tag = 'div',
  children,
  ...props
}, ref) {
  const html = !isString(children) ? '' : children;
  return (
    <Tag
      ref={ ref }
      dangerouslySetInnerHTML={ { __html: html } }
      { ...props }
    />
  );
});