import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  --max-width: 17rem;

  position: absolute;
  width: calc(100vw - 2rem);
  top: 50%;
  left: 50%;
  padding: 1.875rem 1.5rem 0.5rem;
  
  @media ${mq.xs} {
    width: min(calc(100vw - 3rem), var(--max-width));
  }
  @media ${mq.sm} {
    --max-width: 25rem;
  }
  @media ${mq.lg} {
    --max-width: 35rem;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    width: 2px;
    height: calc(var(--radius) * (var(--gap) - 1));
    background-color: var(--color-artica-snow);
    translate: -50% 0;
  }

  p {
    font-size: 0.9em;
  }
`;