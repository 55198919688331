import { css } from '@emotion/react';

export default css`
  isolation: isolate;
  position: relative;
  height: var(--picker-height, 20rem);
  padding-left: 1rem;
  padding-right: 1rem;
  pointer-events: auto;
  font-feature-settings: "tnum";

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background-color: var(--color-artica-snow);
  }

  &[disabled] {
    &, * {
      pointer-events: none;
    }
  }
`;

export const list = css`
  position: relative;
  z-index: 0;
  height: 100%;
  mask: linear-gradient(
    0deg,
    transparent 0%,
    black 45%,
    black 55%,
    transparent 100%
  );

  ul {
    --x-alignment: 1.05rem;

    width: calc(100% + (var(--x-alignment) * 2));
    position: relative;
    height: 100%;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    overscroll-behavior: none;
    padding: calc(var(--picker-height, 20rem) / 2) 0 calc(var(--picker-height, 20rem) / 2) 2.5rem;
    translate: calc(var(--x-alignment) * -1) 0;

    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

    li {
      scroll-snap-align: center;
      word-spacing: 0.2rem;
      padding: 0.825rem 0;
      font-size: 1rem;
      cursor: pointer;

      &[data-active="true"] {
        font-weight: bold;
      }
    }
`;

export const indicator = css`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  pointer-events: none;
  translate: -17px -50%;
`;