import { parallax, Visible } from '@immutabl3/alchemy';
import style from './TelescopeNumbers.style';
import telescopeNumbers from './telescopeNumbers.animation.json';
import NumberSpinner from '../../NumberSpinner';

const Numbers = () => {
  return (
    <parallax.div css={ style } beaker="chapter1" animation={ telescopeNumbers }>
      <div>
        <NumberSpinner value="2414646" />
      </div>
      <div>
        <NumberSpinner value="624646456444" /><i />
        <NumberSpinner value="555" /><i />
        <NumberSpinner value="2464" /><i />
        <NumberSpinner value="24" />
      </div>
      <div>
        <NumberSpinner value="731906962" /><i />
        <NumberSpinner value="542552" /><i />
        <NumberSpinner value="23" />
      </div>
    </parallax.div>
  );
};

export default function TelescopeNumbers() {
  return (
    <Visible beaker="chapter1" animation={ telescopeNumbers }>
      <Numbers />
    </Visible>
  );
};