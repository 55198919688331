import { Debug } from '@immutabl3/alchemy';
import CrossChapters from './CrossChapters';
import Chapter1 from './Chapter1';
import Chapter2 from './Chapter2';
import Chapter3 from './Chapter3';
import Intermission1 from './Intermission1';
import Intermission2 from './Intermission2';
import dynamic from 'next/dynamic';
import { isDev } from '@artica/constants';
import JourneyContainer from './JourneyContainer';

const Scene = dynamic(() => import('./canvas/Scene.jsx'), {
  ssr: false,
});

export default function Journey({
  StoreContext,
  SignalContext,
  AlchemyContext,
}) {
  return (
    <JourneyContainer>
      <Scene
        StoreContext={ StoreContext }
        SignalContext={ SignalContext }
        AlchemyContext={ AlchemyContext }
      />
      <CrossChapters />
      { isDev && <Debug /> }

      <Chapter1 />
      { isDev && <Debug beaker="chapter1" /> }

      <Intermission1 />
      { isDev && <Debug beaker="intermission1" /> }
      
      <Chapter2 />
      { isDev && <Debug beaker="chapter2" /> }

      <Intermission2 />
      { isDev && <Debug beaker="intermission2" /> }
      
      <Chapter3 />
      { isDev && <Debug beaker="chapter3" /> }
    </JourneyContainer>
  );
};