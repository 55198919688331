import { css } from '@emotion/react';
import {
  borderClipPath,
  overflowedGradientBg,
} from '@artica/theme';

export default css`
  isolation: isolate;
  position: relative;
  padding: 1.125rem 2.5rem 1.25rem;
  color: currentColor;
  text-shadow: 0 0 0.125rem hsl(0deg 0% 0% / 0.5);
  background-color: rgb(0 0 0 / 0);
  backdrop-filter: blur(0);

  transition:
    text-shadow 400ms ease,
    background-color 400ms ease,
    backdrop-filter 400ms ease;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    ${overflowedGradientBg}
    ${borderClipPath};
  }

  &:hover {
    background-color: rgb(0 0 0 / 0.05);
    backdrop-filter: blur(10px);
    text-shadow: 0 0 0.2rem hsl(0deg 0% 100% / 0.3);

    &:after {
      background-position: 50% 50%;
    }
  }
`;