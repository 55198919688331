import { css } from '@emotion/react';
import { mq, overflowedGradientBg } from '@artica/theme';

export default css`
  isolation: isolate;
  position: relative;
  width: 6.25rem;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  padding: 0 0.5rem;
  color: var(--color-artica-snow);
  text-shadow: 0 0 0.125rem hsl(0deg 0% 0% / 0.5);
  font-family: var(--font-family-display);
  background-image: linear-gradient(
    180deg,
    rgb(0 0 0 / 0.15) 0%,
    rgb(0 0 0 / 0.05) 100%
  );

  @media ${mq.sm} {
    margin-bottom: 10rem;
  }
  @media ${mq.md} {
    margin-bottom: 8rem;
  }
  @media ${mq.lg} {
    margin-bottom: 14rem;
  }

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    ${overflowedGradientBg}
    mask: radial-gradient(
      circle,
      transparent 0%,
      transparent 68%,
      black 69%,
      black 100%
    );
  }

  &:hover {
    text-shadow: 0 0 0.2rem hsl(0deg 0% 100% / 0.3);

    &:after {
      background-position: 50% 50%;
    }
  }
`;