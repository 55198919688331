import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  width: var(--display-width);
  margin: auto auto 0 auto;

  @media ${mq.sm} {
    width: calc(var(--display-width) * 1.275);
    margin-bottom: 5rem;
  }
  @media ${mq.md} {
    margin-bottom: 0;
  }
  @media ${mq.lg} {
    width: var(--display-width-m-flex);
  }
`;

export const mask = css`
  position: absolute;
  left: 0;
  width: 100vw;
  height: 30vw;

  &[data-position="top"] {
    top: 0;
    mask: linear-gradient(
      to top,
      transparent 0%,
      black 30%
    );
  }
  &[data-position="bottom"] {
    bottom: 0;
    mask: linear-gradient(
      to bottom,
      transparent 0%,
      black 30%
    );
  }
`;