import { Frame, parallax } from '@immutabl3/alchemy';
import postselection from './postselection.animation.json';
import style from './PostSelection.style';

const { first, second, third } = postselection;

export default function PostSelection() {
  return (
    <>
      <Frame
        flex="column"
        beaker="chapter1"
        css={ style }
      >
        <parallax.p beaker="chapter1" animation={ first } className="strong text-display">
          ...there's so much data and so little time.
        </parallax.p>
        <parallax.p beaker="chapter1" animation={ second } className="text-display">
          What data matters? It's not the data that's important, it's the insight that comes from it.
        </parallax.p>
      </Frame>
      <Frame
        flex="column"
        beaker="chapter1"
        css={ style }
      >
        <parallax.p beaker="chapter1" animation={ third } className="text-display">
          And the time it takes to extract insight from data is simply time you don't have, even though you know that insight has value.
        </parallax.p>
      </Frame>
    </>
  );
};