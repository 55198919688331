import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export const first = css`
  width: var(--display-width);
  margin: auto auto 0 auto;
  padding: 0 0 1.5rem 0;

  @media ${mq.sm} {
    width: var(--display-width-xl);
    margin-bottom: 5rem;
  }
  @media ${mq.lg} {
    width: var(--display-width-m-flex);
  }
`;

export const second = css`
  width: var(--display-width);
  margin: auto auto 0 auto;
  padding: 0 0 1.5rem 0;

  @media ${mq.sm} {
    width: var(--display-width-l);
    margin-bottom: 5rem;
  }
  @media ${mq.lg} {
    width: var(--display-width-m-flex);
  }
`;

export const last = css`
  width: var(--display-width);
  margin: auto auto 0 auto;
  padding: 0 0 1.5rem 0;

  @media ${mq.sm} {
    width: var(--display-width-xl);
    margin-bottom: 5rem;
  }
  @media ${mq.lg} {
    width: var(--display-width-m-flex);
  }
`;

export const mask = css`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 45vh;
  background-color: rgb(0 0 0 / 0.3);
  mask: linear-gradient(
    to bottom,
    transparent 0%,
    black 30%
  );
`;