import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export const top = css`
  width: var(--display-width);
  margin: 5rem auto auto auto;

  @media ${mq.sm} {
    width: var(--display-width-xl);
  }
  @media ${mq.lg} {
    width: var(--display-width-xxl);
  }
`;

export const bottom = css`
  position: relative;
  width: var(--display-width);
  height: 11rem;
  margin: auto auto 0 auto;

  @media ${mq.sm} {
    width: var(--display-width-xl);
    height: 16rem;
  }
  @media ${mq.md} {
    height: 11rem;
  }
  @media ${mq.lg} {
    width: var(--display-width-xxl);
    height: 13rem;
  }

  &:before {
    content: 'Trust ';
    font-weight: 700;
  }

  p {
    position: absolute;
    top: 0;
    left: 0;

    strong { visibility: hidden; }
  }
`;

export const topMask = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 25vh;
  mask: linear-gradient(
    to top,
    transparent 0%,
    black 20%
  );
`;

export const bottomMask = css`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 40vh;
  background-image: linear-gradient(
    to bottom,
    hsl(221deg 10% 20% / 0) 0%,
    hsl(221deg 16% 19% / 0.5) 20%,
    hsl(221deg 16% 10%) 70%,
    hsl(221deg 16% 0%) 100% 
  );
  mask: linear-gradient(
    to bottom,
    transparent 0%,
    black 30%
  );
`;