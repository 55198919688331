import style from './List.style';
import Item from './Item';

export default function List({ items, active }) {
  return (
    <ul css={ style } style={ { '--offset': `${active * -100}%` } }>
      { items.map((markdown, idx) => (
        <Item key={ idx }>
          { markdown }
        </Item>
      )) }
    </ul>
  );
};