import Link from '../../Link';
import style from './Disclaimer.style';
import { useStore } from '@immutabl3/store/react';

export default function Disclaimer() {
  const links = useStore(['pageProps', 'nav', 'sublinks']);

  return (
    <small css={ style }>
      By continuing, you accept our
      { links.map(({ id, url, name }) => (
        <span key={ id }>
          <Link href={ url }>{ name }</Link>
        </span>
      )) }.
    </small>
  );
};