import { css } from '@emotion/react';

export default css`
  position: absolute;
  z-index: 0;
  inset: 0;
  will-change: opacity;

  &[data-color="light"] {
    background-color: var(--color-artica-snow);
  }
  &[data-color="dark"] {
    background-color: var(--color-artica-midnight);
  }
`;