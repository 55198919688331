import { Frame, parallax } from '@immutabl3/alchemy';
import animation from './valueproposition.animation';
import {
  first as firstStyle,
  second as seecondStyle,
  last as lastStyle,
  mask as maskStyle,
} from './ValueProposition.style';

const {
  first,
  firstMask,
  second,
  secondMask,
  last,
  lastMask,
} = animation;

export default function ValueProposition() {
  return (
    <>
      <Frame flex="column" beaker="chapter2">
        <parallax.div css={ maskStyle } beaker="chapter2" animation={ firstMask } />
        <parallax.p css={ firstStyle } beaker="chapter2" animation={ first } className="text-display">
          <strong>We offer a suite of digital tools</strong><br />
          that strategically analyze market conditions, supply chain, retail environments, and consumer sentiment.
        </parallax.p>
      </Frame>
      <Frame flex="column" beaker="chapter2">
        <parallax.div css={ maskStyle } beaker="chapter2" animation={ secondMask } />      
        <parallax.p css={ seecondStyle } beaker="chapter2" animation={ second } className="text-display">
          We advise you on business optimization strategies and then <strong>automate the needed actions for you 24/7/365.</strong>
        </parallax.p>
      </Frame>
      <Frame flex="column" beaker="chapter2">
        <parallax.div css={ maskStyle } beaker="chapter2" animation={ lastMask } />      
        <parallax.p css={ lastStyle } className="h3 text-display" beaker="chapter2" animation={ last }>
          We answer the questions you needed answers to...
        </parallax.p>
      </Frame>
    </>
  );
};