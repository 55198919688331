import { css } from '@emotion/react';

export default css`
  position: absolute;
  z-index: 0;
  inset: 0;
  opacity: 1;
  background-color: rgb(149 152 147);
  background-image: radial-gradient(
    circle,
    rgb(149 152 147) 0%,
    rgb(33 36 54) 100%
  );
  will-change: opacity;
  transition: opacity 300ms linear;

  &[data-loaded="true"] {
    opacity: 0;
  }
`;