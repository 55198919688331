import LoadFade from './LoadFade';
import Intermission1Mask from './Intermission1Mask';
import Intermission2Mask from './Intermission2Mask';
import PreloadTransitionMask from './PreloadTransitionMask';
import ScrollProgress from './ScrollProgress';

export default function CrossChapters() {
  return (
    <>
      <LoadFade />
      <PreloadTransitionMask />
      <Intermission1Mask />
      <Intermission2Mask />
      <ScrollProgress />
    </>
  );
};