import { parallax } from '@immutabl3/alchemy';
import step2main from './step2main.animation.json';
import step2sub from './step2sub.animation.json';
import style from './Step2.style';

export default function Step2() {
  return (
    <parallax.div css={ style } beaker="chapter1" animation={ step2main }>
      <div>
        <p className="text-display">
          Something is telling you getting to great requires knowledge you don't have and technology you don't possess.
        </p>
        <parallax.p className="strong text-display" beaker="chapter1" animation={ step2sub }>
          Yet.
        </parallax.p>
      </div>
    </parallax.div>
  );
};