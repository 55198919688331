import style from './Mask.style';
import { parallax } from '@immutabl3/alchemy';
import animation from './intermission2.animation.json';

export default function Intermission2Mask() {
  return (
    <parallax.div
      css={ style }
      animation={ animation }
      data-color="dark"
      className="no-interact"
    />
  );
};