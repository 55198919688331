import { parallax } from '@immutabl3/alchemy';
import style, { mask as maskStyle } from './Opener.style';
import animation from './opener.animation';

const { main, sub, mask } = animation;

export default function Opener() {
  return (
    <>
      <parallax.div css={ maskStyle } data-position="top" beaker="chapter2" animation={ mask } />
      <parallax.div css={ maskStyle } data-position="bottom" beaker="chapter2" animation={ mask } />
    
      <parallax.div css={ style } beaker="chapter2" animation={ main }>
        <p className="h2 text-display">
          Meet Artica
        </p>
        <parallax.p beaker="chapter2" animation={ sub } className="text-display">
          We use market and consumer data to gain a deep understanding of buyer behavior so that you can intelligently optimize the buying experience, both for your business and your customers.
        </parallax.p>
      </parallax.div>
    </>
  );
};