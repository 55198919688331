import { Frame, parallax } from '@immutabl3/alchemy';
import style from './Finale.style';
import animation from './finale.animation.json';
import FinaleInsights from './FinaleInsights';
import FinaleContact from './FinaleContact';

export default function Finale() {
  return (
    <>
      <Frame flex="column" css={ style } beaker="chapter3">
        <FinaleContact />
      </Frame>
      <Frame flex="column" css={ style } beaker="chapter3">
        <parallax.p className="h3 center" beaker="chapter3" animation={ animation }>
          We build incredible businesses.
        </parallax.p>
        <FinaleInsights />
      </Frame>
    </>
  );
};