import { Frame } from '@immutabl3/alchemy';
import Mask from './Mask';
import Intro from './Intro';
import InsightPicker from './InsightPicker';
import PostSelection from './PostSelection';
import style from './Selection.style';

export default function Chapter1Selection() {
  return (
    <>
      <Frame
        flex="column"
        beaker="chapter1"
        css={ style }
      >
        <Mask />
        <Intro />
        <InsightPicker />
      </Frame>
      <PostSelection />
    </>
  );
};