// inspired by
// https://codepen.io/maxakohler/pen/JZgXxe
// https://codepen.io/eefozeo/pen/MROGPL
import { useCallback } from 'react';
import { noop } from '@immutabl3/utils';
import Triangle from './icons/Triangle';
import style, {
  list as listStyle,
  indicator as indicatorStyle,
} from './Picker.style';
import { usePicker } from './Picker.hooks';

const PickerItem = props => {
  const {
    id,
    name,
    active,
    onSelect,
  } = props;

  return (
    <li
      key={ id }
      onClick={ useCallback(() => onSelect(id), [id]) }
      data-active={ active }
    >
      { name }
    </li>
  );
};

const prevent = e => e.stopPropagation();

export default function Picker({
  className,
  defaultValue,
  onChange = noop,
  options = [],
  disabled = false,
}) {
  const {
    ref,
    value,
    onScroll,
    onSelect,
  } = usePicker(
    options,
    onChange,
    defaultValue,
  );

  return (    
    <div
      css={ style }
      className={ className }
      disabled={ disabled }
      onWheel={ !disabled ? prevent : undefined }
      onMouseDown={ !disabled ? prevent : undefined }
      onTouchStart={ !disabled ? prevent : undefined }
      onTouchMove={ !disabled ? prevent : undefined }
    >
      <div css={ listStyle }>
        <ul ref={ ref } onScroll={ onScroll }>
          { options.map(props => (
            <PickerItem
              { ...props }
              key={ props.id }
              onSelect={ onSelect }
              active={ props.id === value.id }
            />
          )) }
        </ul>
      </div>
      <Triangle
        css={ indicatorStyle }
        right
      />
    </div>
  );
};