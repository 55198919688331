import Spinner from '../../Spinner';
import { Frame } from '@immutabl3/alchemy';
import { useStore } from '@immutabl3/store/react';
import style, { container as containerStyle } from './Loader.style';

export default function Loader() {
  const loaded = useStore(['loaded']);
  const sceneloaded = useStore(['sceneloaded']);
  const isLoaded = loaded && sceneloaded;
  return (
    <Frame flex="column" beaker="chapter1" css={ containerStyle }>
      <div css={ style } data-loaded={ isLoaded }>
        <small>
          Loading
        </small>
        <Spinner />
      </div>
    </Frame>
  );
};