import { useRef, useEffect } from 'react';
import { wrapIndex } from '@immutabl3/utils';
import style from './NumberSpinner.style';

const NUMBERS = `0123456789`.split('');
const SPEED_MS = 50;
const STEP_SCALE = 7;

const useCounterAnimation = (index, value, active, speed, stepScale) => {
  const ref = useRef();

  useEffect(() => {
    if (!active) return;
    
    const iterations = index * stepScale;

    let i = NUMBERS.indexOf(value);

    const interval = setInterval(() => {
      i += 1;
      const nextIndex = wrapIndex(NUMBERS, i);
      ref.current.setAttribute('data-value', NUMBERS[nextIndex]);
      if (i > iterations) clearInterval(interval);
    }, speed);

    return () => clearInterval(interval);
  }, [index, value, active, speed, stepScale]);

  return ref;
};

const NumberCounter = ({
  value = 0,
  index = 0,
  active = true,
  speed = SPEED_MS,
  stepScale = STEP_SCALE,
}) => {
  return (
    <span
      ref={ useCounterAnimation(index, value, active, speed, stepScale) }
      css={ style }
      data-value={ value }
    />
  );
};

export default function NumberSpinner({
  value,
  active,
  speed,
  stepScale,
}) {
  return value.split('').map((value, index) => (
    <NumberCounter
      key={ index }
      value={ value }
      index={ index }
      active={ active }
      speed={ speed }
      stepScale={ stepScale }
    />
  ));
};
