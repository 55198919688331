import style, { bar as barStyle } from './ScrollProgress.style';
import { parallax } from '@immutabl3/alchemy';

const onPercChange = (perc, ref) => {
  const bar = ref?.current;
  if (!bar) return;
  bar.style.setProperty('--perc', perc);
};

export default function PreloadTransitionMask() {
  return (
    <div
      css={ style }
      className="no-interact"
    >
      <parallax.div
        css={ barStyle }
        onPercChange={ onPercChange }
      />
    </div>
  );
};