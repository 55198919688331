import { Frame, parallax } from '@immutabl3/alchemy';
import animation from './headline.animation';
import style, {
  mask as maskStyle,
} from './Headline.style';

const { mask, main } = animation;

export default function Closing() {
  return (
    <Frame beaker="chapter1" flex="column">
      <parallax.div css={ maskStyle } beaker="chapter1" animation={ mask } />
      <parallax.p css={ style } className="h3 strong text-display" beaker="chapter1" animation={ main }>
        Data can help uncover the right questions
      </parallax.p>
    </Frame>
  );
};