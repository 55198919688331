import style from './LoadFade.style';
import { useStore } from '@immutabl3/store/react';

export default function LoadFade() {
  const sceneloaded = !!useStore(['sceneloaded']);
  return (
    <div
      css={ style }
      data-loaded={ sceneloaded }
      className="no-interact"
    />
  );
};