import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  max-width: var(--display-width-m-flex);
  margin: auto;
  translate: 0 -225%;

  h1 {
    line-height: 1.2;
    // reduce font size with increase in qty of text
    font-size: calc(var(--font-size-base) * 1.5);
  
    @media ${mq.sm} {
      margin-bottom: 0.5em;
    }
  }

  @media ${mq.sm} {
    translate: 0 -250%;
  }

  @media ${mq.md} {
    translate: 0 -200%;
  }
  
  @media ${mq.lg} {
    max-width: var(--display-width-xxl);
    translate: 0 -250%;
  }
`;