import { css } from '@emotion/react';
import { mq } from '@artica/theme';

export default css`
  position: absolute;
  inset: 0;

  > div {
    position: absolute;

    bottom: 2.875rem;
    left: 50%;
    max-height: 12rem;
    width: 100vw;
    max-width: var(--display-width-l);
    padding: 2rem;
    border-right: 1px solid var(--color-artica-snow);
    background-color: hsl(226deg 23% 17% / 0.8);
    translate: -50% 0;

    @media ${mq.sm} {
      top: 50%;
      left: 50%;
      bottom: auto;
      width: 50vw;
      scale: 1.2;
      translate: -50% 150%;
      border-right: none;
      border-left: 1px solid var(--color-artica-snow);
    }
    @media ${mq.md} {
      width: 50vw;
      translate: 5vw 20vh;
    }
    @media ${mq.lg} {
      max-width: var(--display-width-xl);
      translate: 0 15vh;
    }
  }
    h1, h2, h3, h4, h5, h6 {
      font-family: var(--font-family);
      font-size: 1em;
    }
    p {
      margin: 0;
      line-height: 1.25;
    }
`;

export const svg = css`
  position: absolute;
  inset: 0;

  line {
    stroke: white;
    stroke-width: 1;
  }
`;
