import style from './Carousel.style';
import List from './List';
import ButtonNav from './ButtonNav';
import ButtonGroupNav from './ButtonGroupNav';
import { useCarousel } from './Carousel.hooks';

export default function Carousel({ items }) {
  const {
    onPrevious,
    onNext,
    onSelect,
    index,
  } = useCarousel(items);

  return (
    <div css={ style }>
      <List
        items={ items }
        active={ index }
      />
      <ButtonNav
        items={ items }
        active={ index }
        onPrevious={ onPrevious }
        onNext={ onNext }
      />
      <ButtonGroupNav
        items={ items }
        active={ index }
        onSelect={ onSelect }
      />
    </div>
  );
};