import { css } from '@emotion/react';

export default css`
  span {
    &:before {
      content: ' ';
    }

    &:not(:last-of-type) {
      &:after {
        content: ' and';
      }
    }
  }
`;